import { AccountTrxTypeCategory } from '@/utils'
import { StmtAccountDetailsResBodyPayloadAccountYtdContributonList } from '@pimy-b2cweb/apiclient-customer-stmt/dist/models/stmt-account-details-res-body-payload-account-ytd-contributon-list'

interface ContributionDetailsProps {
  titleHeader: string | null
  accountActiveTab: string | null
  ytdContributionDetails?: StmtAccountDetailsResBodyPayloadAccountYtdContributonList
}

const ContributionDetails = ({
  accountActiveTab,
  ytdContributionDetails,
}: ContributionDetailsProps) => {
  const getTitleHeader = (accountActiveTab: string | null) => {
    let titleHeader
    if (accountActiveTab === AccountTrxTypeCategory.ACCOUNT_DETAILS) {
      titleHeader = 'Account Details'
    } else if (
      accountActiveTab === AccountTrxTypeCategory.TRANSACTION_HISTORY
    ) {
      titleHeader = 'Transaction Details'
    } else if (accountActiveTab === AccountTrxTypeCategory.RSP) {
      titleHeader = 'Regular Savings Plan'
    } else if (accountActiveTab === AccountTrxTypeCategory.RWP) {
      titleHeader = 'Regular Withdrawal Plan'
    } else if (
      accountActiveTab === AccountTrxTypeCategory.DISTRIBUTOR_DETAILS
    ) {
      titleHeader = 'Distributor Details'
    }
    return titleHeader
  }

  return (
    <div className='mt-4'>
      <h2 className='text-lg font-semibold mb-4'>
        {/* Contribution Summary &gt; {titleHeader} */}
        {getTitleHeader(accountActiveTab)}
      </h2>
      {accountActiveTab === AccountTrxTypeCategory.ACCOUNT_DETAILS && (
        <div>
          {ytdContributionDetails?.accountNo && (
            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[20%]
             mb-1'
              >
                PRS Plus Account No
              </label>
              <span className='border-2 border-black w-[30%] h-[10%] bg-pi-gray-4 p-[2px]'>
                {ytdContributionDetails?.accountNo}
              </span>
            </div>
          )}
          {ytdContributionDetails?.ytdContribution !== undefined &&
            ytdContributionDetails?.ytdContribution !== null && (
              <div className='flex gap-8 mb-2'>
                <label
                  className='text-right block text-sm font-medium text-pi-gray-2 w-[20%]
             mb-1'
                >
                  Year To Date Contribution (RM)
                </label>
                <span className='border-2 border-black w-[30%] h-[10%] bg-pi-gray-4 p-[2px]'>
                  {Number(
                    Number(ytdContributionDetails?.ytdContribution).toFixed(2)
                  )}
                </span>
              </div>
            )}
          {ytdContributionDetails?.totalContribution !== undefined &&
            ytdContributionDetails.totalContribution !== null && (
              <div className='flex gap-8 mb-2'>
                <label
                  className='text-right block text-sm font-medium text-pi-gray-2 w-[20%]
             mb-1'
                >
                  Total Contribution to Date (RM)
                </label>
                <span className='border-2 border-black w-[30%] h-[10%] bg-pi-gray-4 p-[2px]'>
                  {Number(
                    Number(ytdContributionDetails.totalContribution).toFixed(2)
                  )}
                </span>
              </div>
            )}
        </div>
      )}
    </div>
  )
}

export default ContributionDetails
