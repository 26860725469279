import { ReactComponent as DownloadIco } from '../../assets/download.svg'
import { ReactComponent as StatementIco } from '../../assets/statement.svg'
import { convertedStatementItemProps } from '../Statements'
import { Button } from '@mui/material'
import { authSessionSlice } from '@/stores/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface StatementReportProps {
  statements: convertedStatementItemProps[]
}

const { setIsUserIdle } = authSessionSlice.actions

const StatementReport: React.FC<StatementReportProps> = ({ statements }) => {
  const { t } = useTranslation('reportsPage')
  const dispatch = useDispatch()
  return (
    <div className='w-[60%] bg-white mt-4'>
      {statements.map(({ month, items }, mIdx) => (
        <div
          key={mIdx}
          className='p-4 border rounded-md bg-gray-50 hover:bg-gray-100'
        >
          <div>
            {/* <div className='font-semibold pb-4'>{month}</div> */}
            <span className='flex gap-4 items-center mb-5 font-bold'>
              <StatementIco />
              {t(month, { ns: 'months' })}
            </span>
            {items.map(({ dateStr, items }, dIdx) => (
              <div className='mb-2' key={`${dateStr}-${dIdx}`}>
                <p className='pl-1 text-sm text-gray-600'>{dateStr}</p>
                {items.map(({ statementType, statementUrl }, sIdx) => (
                  <Button
                    key={`${month}-${mIdx}-${dateStr}-${dIdx}-${statementType}-${sIdx}`}
                    endIcon={<DownloadIco />}
                    className='w-full bg-pi-sky-blue-1 hover:bg-pi-sky-blue-2 py-3 px-4 mb-2'
                    onClick={() => {
                      dispatch(setIsUserIdle())
                      console.log('url=%s', statementUrl)
                      if (!!statementUrl) {
                        window.open(statementUrl, '_blank')
                      }
                    }}
                  >
                    <span className='grow text-left font-normal text-pi-dark-prussian-blue'>
                      {t(statementType || '', {
                        ns: 'statementTypes',
                      })}
                    </span>
                  </Button>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default StatementReport
