import { Card } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MainLogo from '@/layout/MainLogo'
import Profile from './components/Profile'
import SessionModal from './components/SessionModal'
import Statements from './components/Statements'

export const DashboardPage = () => {
  const { t } = useTranslation(['dashboardPage', 'common'])

  return (
    <div className='col-span-full'>
      <MainLogo />
      <Card className='sm:mb-6'>
        <h2 className='titleTxt'>{t('Profile Info')}</h2>
        <Profile />
      </Card>
      <hr className='border-0 border-b border-pi-gray-3  -mx-4 mt-[10px] mb-6 sm:hidden' />
      <Card>
        <Statements />
      </Card>
      <SessionModal />
    </div>
  )
}

export default DashboardPage
