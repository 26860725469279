import { StatementAccountTypeCategory, StatementTypeCategory } from '@/utils'

interface TabSwitcherProps {
  tabs: string[]
  activeTab: string | null
  onTabChange: (tab: string) => void
  selectedCategory?: string
}

const TabSwitcher = ({
  tabs,
  activeTab,
  onTabChange,
  selectedCategory,
}: TabSwitcherProps) => {
  const filteredTabs =
    selectedCategory === StatementTypeCategory.UT
      ? tabs.filter((tab) => tab !== StatementAccountTypeCategory.ACCOUNT)
      : tabs
  return (
    <div className='flex gap-6 mt-6 w-full'>
      {filteredTabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          className={`px-[5%] py-2 rounded-full cursor-pointer ${
            activeTab === tab
              ? 'bg-pi-sky-blue-1 text-pi-principal-blue border-pi-principal-blue'
              : 'bg-[#fff] text-black'
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  )
}

export default TabSwitcher
