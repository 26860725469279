import { CircularProgress } from '@mui/material'
import { StmtDistributorDetailsResBodyPayloadDistributorDetailsInner } from '@pimy-b2cweb/apiclient-customer-stmt/dist/models/stmt-distributor-details-res-body-payload-distributor-details-inner'

interface ContributionDetailsProps {
  distributorDetails:
    | StmtDistributorDetailsResBodyPayloadDistributorDetailsInner
    | undefined
  status: string
}

const DistributorDetails = ({
  distributorDetails,
  status,
}: ContributionDetailsProps) => {
  return (
    <>
      {status === 'loading' ? (
        <div className='w-full h-[10vh] m-0 flex justify-center items-center'>
          <CircularProgress className='text-pi-principal-blue' />
        </div>
      ) : (
        <div className='mt-4 flex flex-row gap-10'>
          <div className='w-[50%]'>
            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                PRS Plus Account No
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  distributorDetails?.accountNo ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {distributorDetails?.accountNo}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Distributor
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  distributorDetails?.distributor ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {distributorDetails?.distributor}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Consultancy Name
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  distributorDetails?.consultantName ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {distributorDetails?.consultantName}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Contact No
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  distributorDetails?.contactNo ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {distributorDetails?.contactNo}
              </span>
            </div>
          </div>
          <div className='w-[50%]'>
            <p className='text-[20px] font-semibold text-gray-700 mb-2'>
              Quick Links
            </p>
            <div className='flex gap-2 mt-4'>
              <div className='flex flex-col items-center w-[100px]'>
                <img
                  src={require('../../assets/fundPrice.JPG')}
                  alt='Fund Price'
                  className='w-[70%] h-[70%] object-contain'
                />
                <p className='text-sm mt-1 text-center'>Fund Price</p>
              </div>

              <div className='flex flex-col items-center w-[100px]'>
                <img
                  src={require('../../assets/fundFactSheet.JPG')}
                  alt='Fund Fact Sheet'
                  className='w-[70%] h-[70%] object-contain'
                />
                <p className='text-sm mt-1 text-center'>Fund Fact Sheet</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DistributorDetails
