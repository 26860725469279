import { CircularProgress } from '@mui/material'

interface DataTableProps<T> {
  headers: string[]
  columnMapping?: { [key: string]: string }
  rows: T[]
  selectedRowIndex: number | null
  handleAccountChecked: (isChecked: boolean, index: number) => void
  isCheckbox?: boolean
  status?: string
}

const DataTable = <T extends { [key: string]: any }>({
  headers,
  columnMapping = {},
  rows = [],
  selectedRowIndex,
  handleAccountChecked,
  isCheckbox,
  status,
}: DataTableProps<T>) => {
  return (
    <>
      {status === 'loading' ? (
        <div className='w-full h-[10vh] m-0 flex justify-center items-center'>
          <CircularProgress className='text-pi-principal-blue' />
        </div>
      ) : (
        <>
          {rows.length > 0 ? (
            <div
              className='pt-4 overflow-x-auto overflow-y-auto border border-pi-gray-2'
              style={{ scrollbarWidth: 'thin' }}
            >
              <table className='w-full border-collapse text-left border border-pi-gray-3'>
                <thead>
                  <tr className='bg-[#007bff] text-white table-auto border-collapse '>
                    {isCheckbox && (
                      <th className='text-left py-2 text-[14px] p-[10px]'>
                        Select
                      </th>
                    )}
                    {headers.map((header) => {
                      return (
                        <th
                          className='text-left py-2 text-[14px] p-[10px]'
                          key={header}
                        >
                          {header}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr
                      key={index}
                      className={`pl-2 ${
                        index % 2 === 1 ? 'bg-pi-gray-4' : ''
                      }`}
                    >
                      {isCheckbox && (
                        <td className='text-center py-2 border '>
                          <input
                            type='checkbox'
                            className='form-checkbox h-4 w-4'
                            checked={selectedRowIndex === index}
                            onChange={(e) =>
                              handleAccountChecked(e.target.checked, index)
                            }
                          />
                        </td>
                      )}
                      {Object.keys(columnMapping).map((key, idx) => {
                        let cellValue = row[columnMapping[key]]

                        if (
                          key === 'Fund Name' &&
                          (cellValue === undefined || cellValue === null)
                        ) {
                          cellValue = row.fundCode
                        }
                        if (
                          key === 'Sub Acc A (RM)' ||
                          key === 'Sub Acc B (RM)'
                        ) {
                          const isSubAccA = key === 'Sub Acc A (RM)'
                          // const validMoneyTypes = isSubAccA
                          //   ? ['EMP-A', 'IND-A']
                          //   : ['EMP-B', 'IND-B']
                          cellValue = isSubAccA ? row.subAccA : row.subAccB
                          cellValue = Number(Number(cellValue).toFixed(2))
                        }

                        if (
                          (key === 'Application Date' ||
                            key === 'Transaction Date' ||
                            key === 'RWP Start Date' ||
                            key === 'RWP End Date' ||
                            key === 'NAV Per Unit as at') &&
                          cellValue
                        ) {
                          const dateObj = new Date(cellValue)
                          const year = dateObj.getFullYear()
                          const month = String(dateObj.getMonth() + 1).padStart(
                            2,
                            '0'
                          )
                          const day = String(dateObj.getDate()).padStart(2, '0')
                          cellValue = `${year}/${month}/${day}`
                        }

                        if (
                          key === 'Current Holding Value(RM)' ||
                          key === 'Gross Amount (RM)' ||
                          key === 'Total Transfer Out' ||
                          key === 'Total Transfer In' ||
                          key === 'Sales Charges (RM)' ||
                          key === 'GST Amount (RM)' ||
                          key === 'PPA Opening Fee (RM)' ||
                          key === 'Total Contribution (RM)' ||
                          key === 'Sub Acc B (RM)' ||
                          key === 'Sub Acc A (RM)' ||
                          key === 'Total Switch In' ||
                          key === 'Total Switch Out' ||
                          key === 'Total Withdrawal' ||
                          key === 'NAV Per Unit (RM)' ||
                          key === 'Current Holding Units' ||
                          (key === 'Current Holding Value (RM)' && cellValue)
                        ) {
                          cellValue = Number(Number(cellValue).toFixed(2))
                        }

                        return (
                          <td
                            key={idx}
                            className='text-left py-2 text-[14px] p-[10px]'
                          >
                            {String(cellValue ?? '')}
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='pt-4'>
              <span className='h-[10vh]'>No item found</span>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default DataTable
