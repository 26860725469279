import { useState } from 'react'

interface UsePaginationProps {
  totalItems: number | undefined
  rowsPerPage: number
  intialPage?: number
  accountDetailsTotalItems: number | undefined
  trxDetailsTotalItems: number | undefined
  rwpDetailsTotalItems: number | undefined
}

const usePagination = ({
  totalItems,
  rowsPerPage = 10,
  intialPage = 1,
  accountDetailsTotalItems,
  trxDetailsTotalItems,
  rwpDetailsTotalItems,
}: UsePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(intialPage)
  const [accountDetailsCurrentPage, setAccountDetailsCurrentPage] =
    useState(intialPage)
  const [trxDetailsCurrentPage, setTrxDetailsCurrentPage] = useState(intialPage)
  const [rwpDetailsCurrentPage, setRwpDetailsCurrentPage] = useState(intialPage)

  const totalPages = Math.ceil((totalItems ?? 0) / rowsPerPage)
  const accountDetailsTotalPages = Math.ceil(
    (accountDetailsTotalItems ?? 0) / rowsPerPage
  )
  const trxDetailsTotalPages = Math.ceil(
    (trxDetailsTotalItems ?? 0) / rowsPerPage
  )
  const rwpDetailsTotalPages = Math.ceil(
    (rwpDetailsTotalItems ?? 0) / rowsPerPage
  )

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((p) => p + 1)
  }

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage((p) => p - 1)
  }

  const handleAccountDetailsNext = () => {
    if (accountDetailsCurrentPage < accountDetailsTotalPages)
      setAccountDetailsCurrentPage((p) => p + 1)
  }
  const handleAccountDetailsPrev = () => {
    if (accountDetailsCurrentPage > 1)
      setAccountDetailsCurrentPage((p) => p - 1)
  }

  const handleTransactionHistoryNext = () => {
    if (trxDetailsCurrentPage < trxDetailsTotalPages)
      setTrxDetailsCurrentPage((p) => p + 1)
  }
  const handleTransactionHistoryPrev = () => {
    if (trxDetailsCurrentPage > 1) setTrxDetailsCurrentPage((p) => p - 1)
  }

  const handleRWPDetailsNext = () => {
    if (rwpDetailsCurrentPage < rwpDetailsTotalPages)
      setRwpDetailsCurrentPage((p) => p + 1)
  }
  const handleRWPDetailsPrev = () => {
    if (rwpDetailsCurrentPage > 1) setRwpDetailsCurrentPage((p) => p - 1)
  }

  return {
    currentPage,
    totalPages,
    handleNext,
    handlePrev,
    accountDetailsCurrentPage,
    accountDetailsTotalPages,
    handleAccountDetailsNext,
    handleAccountDetailsPrev,
    handleRWPDetailsNext,
    handleRWPDetailsPrev,
    trxDetailsCurrentPage,
    trxDetailsTotalPages,
    rwpDetailsTotalPages,
    rwpDetailsCurrentPage,
    handleTransactionHistoryNext,
    handleTransactionHistoryPrev,
    setCurrentPage,
    setAccountDetailsCurrentPage,
    setTrxDetailsCurrentPage,
    setRwpDetailsCurrentPage,
  }
}

export default usePagination
