import { Suspense } from 'react'
import { connect, useSelector } from 'react-redux'
import { StatusEnum } from '@/config'
import LoadingPage from '@/pages/Loading'
import LoginPage from '@/pages/Login'
import OtpPage from '@/pages/Otp'
import DashboardPage from '@/pages/Dashboard'
import { MainBody as MainBodyUi } from '@pimy-b2cweb/frontend-lib'
import InitSearchParams from '@/components/InitSearchParams'
//import Delisting from '@/pages/Delisting'
import DelistingClosed from '@/pages/DelistingClosed'
import { selectDelistingAccountNo } from '@/stores/delisting.selectors'

export const MainBody = () => {
  return (
    <MainBodyUi>
      <Suspense fallback={<LoadingPage />}>
        <InitSearchParams />
        <PageSwitch />
      </Suspense>
    </MainBodyUi>
  )
}

const PageSwitch = connect((state: any) => ({
  status: state.authSession.status,
}))(({ status }: { status: StatusEnum }) => {
  const accountNo = useSelector(selectDelistingAccountNo)
  const isActiveDelistingSession = !!accountNo

  if (isActiveDelistingSession) {
    return <DelistingClosed />
  }
  switch (status) {
    case StatusEnum.PendingOtp:
      return <OtpPage />
    case StatusEnum.LoggedIn:
      return <DashboardPage />
    default:
      return <LoginPage />
  }
})

export default MainBody
