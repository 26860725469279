import { connect } from 'react-redux'
import { StatusEnum } from '@/config'
import { useTranslation } from 'react-i18next'
import DelistingClosedLayout from '@/layout/DelistingClosedLayout'

const DelistingClosed = connect((state: any) => ({
  status: state.authSession.status,
}))(({ status }: { status: StatusEnum }) => {
  const { t } = useTranslation('delisting')
  return (
    <DelistingClosedLayout>
      <h2>{t('link-is-no-longer-active')}</h2>

      <h2>{t('further-assistance')}</h2>
      <ul>
        <li>WhatsApp chat at 016-299 9792</li>
        <li>
          Email at{' '}
          <a href='mailto:myservice@principal.com'>myservice@principal.com</a>
        </li>
        <li>Call at 03-7723 7260</li>
      </ul>
    </DelistingClosedLayout>
  )
})
export default DelistingClosed
