import { Button, CircularProgress } from '@mui/material'
import { StmtRecentResBodyPayloadStatementsInner as StatementItem } from '@pimy-b2cweb/apiclient-customer-stmt'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CATEGORIZED_STATEMENT_TYPES } from '@pimy-b2cweb/common'
import { ButtonWithLoading } from '@pimy-b2cweb/frontend-lib'
import {
  useQueryStmtAccountDetails,
  useQueryStmtContributionSummary,
  useQueryStmtDistributorDetails,
  useQueryStmtRecent,
  useQueryStmtRspDetails,
  useQueryStmtRwpDetails,
  useQueryStmtTransactionDetails,
} from '@/api/'
import { authSessionSlice } from '@/stores/auth'
import {
  AccountTrxTypeCategory,
  StatementAccountTypeCategory,
  StatementTypeCategory,
  contributionSummaryColumnMapping,
  accountDetailsColumnMapping,
  getErrorResponseCode,
  rwpDetailsColumnMapping,
  trxDetailsColumnMapping,
  accountsType,
} from '@/utils'
import useNotifications from '@/hooks/useNotifications/'
import Pagination from '../Pagination'
import TabSwitcher from '../TabSwitcher'
import DataTable from '../DataTable'
import StatementReport from '../StatementReport'
import ContributionDetails from '../ContributionDetails'
import { selectProfileSessionState } from '@/stores/profile.selectors'
import usePagination from '@/hooks/usePagination'
import RSPDetails from '../RSPDetails'
import DistributorDetails from '../DistributorDetails'

dayjs.extend(utc)
const { setIsUserIdle } = authSessionSlice.actions

export interface convertedStatementItemProps {
  month: string
  items: {
    dateStr: string
    items: StatementItem[]
  }[]
}

const NUM_PER_BETCH = 10

const getCategorizedStatements = (
  data: StatementItem[],
  selectedCategory: StatementTypeCategory.UT | StatementTypeCategory.PRS
) => {
  const categorizedStatementTypes: string[] =
    CATEGORIZED_STATEMENT_TYPES[selectedCategory]
  return [
    ...data.filter((item) =>
      categorizedStatementTypes.includes(item.statementType)
    ),
  ]
}

export const Statements = () => {
  const { idNo, idType } = useSelector(selectProfileSessionState)

  const { t } = useTranslation([
    'dashboardPage',
    'months',
    'statementTypeCategories',
    'statementTypes',
  ])

  const dispatch = useDispatch()
  const [itemsNum, setItemsNum] = useState(NUM_PER_BETCH)
  const [selectedCategory, setSelectedCategory] = useState(
    StatementTypeCategory.UT
  )
  const [visibleCategories, setVisibleCategories] = useState<
    StatementTypeCategory[]
  >([])

  const { data, status, error } = useQueryStmtRecent()
  const [accountNumber, setAccountNumber] = useState<string | null>(null)

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [activeTab, setActiveTab] = useState<string | null>(
    StatementAccountTypeCategory.STATEMENT
  )
  const [accountActiveTab, setAccountActiveTab] = useState<string | null>('')
  const [isAccountSectionVisiable, setIsAccountSectionVisiable] =
    useState<boolean>(false)

  useEffect(() => {
    const accountTypes = data?.accountsList?.map((acc) => acc.accountType) ?? []
    const categories: StatementTypeCategory[] = []

    if (accountTypes.includes(accountsType.FIN_ACCOUNT)) {
      categories.push(StatementTypeCategory.UT)
    }

    if (accountTypes.includes(accountsType.PRS_ACCOUNT)) {
      categories.push(StatementTypeCategory.PRS)
    }
    setVisibleCategories(categories)

    if (categories.length > 0) {
      setSelectedCategory(categories[0])
    }
  }, [data?.accountsList])

  useEffect(() => {
    if (data && data?.accountsList && data.accountsList.length > 0) {
      const prsAccount = data.accountsList.find(
        (acc) => acc.accountType === accountsType.PRS_ACCOUNT
      )
      if (prsAccount?.accountNo) {
        setAccountNumber(prsAccount.accountNo)
      }
    }
  }, [data])

  const {
    data: contributionSummaryResults,
    status: contributionSummaryStatus,
  } = useQueryStmtContributionSummary(
    {
      accountNo: accountNumber!,
      idNo,
    },
    { enabled: activeTab === StatementAccountTypeCategory.ACCOUNT }
  )

  const { data: accountDetailsResults, status: accountDetailsStatus } =
    useQueryStmtAccountDetails(
      {
        accountNo: accountNumber!,
        idNo,
      },
      { enabled: accountActiveTab === AccountTrxTypeCategory.ACCOUNT_DETAILS }
    )

  const { data: transactionDetailsResults, status: transactionDetailsStatus } =
    useQueryStmtTransactionDetails(
      {
        accountNo: accountNumber!,
        idType: idType || '',
        idNo,
      },
      {
        enabled:
          accountActiveTab === AccountTrxTypeCategory.TRANSACTION_HISTORY,
      }
    )

  const { data: rspDetailsResults, status: rspDetailsStatus } =
    useQueryStmtRspDetails(
      {
        accountNo: accountNumber!,
        idNo,
      },
      {
        enabled: accountActiveTab === AccountTrxTypeCategory.RSP,
      }
    )

  const { data: rwpDetailsResults, status: rwpDetailsStatus } =
    useQueryStmtRwpDetails(
      {
        accountNo: accountNumber!,
        idNo,
      },
      {
        enabled: accountActiveTab === AccountTrxTypeCategory.RWP,
      }
    )

  const { data: distributorDetailsResults, status: distributorDetailsStatus } =
    useQueryStmtDistributorDetails(
      {
        accountNo: accountNumber!,
        idNo,
      },
      {
        enabled:
          accountActiveTab === AccountTrxTypeCategory.DISTRIBUTOR_DETAILS,
      }
    )

  const handleCheckboxChange = (isChecked: boolean, index: number) => {
    setSelectedRowIndex(isChecked ? index : null)
    setIsAccountSectionVisiable(isChecked)
    if (isChecked) {
      setAccountActiveTab(AccountTrxTypeCategory.ACCOUNT_DETAILS)
    }
  }

  const transformedPaginatedData = () => {
    const groupedData: { [key: string]: any } = {}
    paginatedData?.forEach((item) => {
      const {
        accountNo,
        moneyType,
        totalContribution,
        totalContributionAmount,
        subAccountA,
        subAccountB,
      } = item

      if (!accountNo) return
      const isNonGhrPhr = !!subAccountA || !!subAccountB ? true : false
      if (!groupedData[accountNo]) {
        groupedData[accountNo] = {
          accountNo,
          investApproach: contributionSummaryResults?.investmentApproach,
          subAccA: 0,
          subAccB: 0,
          totalContribution: 0,
          totalContributionAmount: 0,
          currentHoldingValue: 0,
          isNonGhrPhr,
        }
      }

      if (isNonGhrPhr) {
        groupedData[accountNo].subAccA += Number(subAccountA)
        groupedData[accountNo].subAccB += Number(subAccountB)
      } else if (moneyType === 'EMP-A' || moneyType === 'IND-A') {
        groupedData[accountNo].subAccA += Number(totalContribution)
      } else if (moneyType === 'EMP-B' || moneyType === 'IND-B') {
        groupedData[accountNo].subAccB += Number(totalContribution)
      }

      groupedData[accountNo].totalContribution += Number(totalContribution)
      groupedData[accountNo].totalContributionAmount += Number(
        totalContributionAmount
      )
    })
    return Object.values(groupedData)
  }

  const transformedAccountDetailsPaginatedData = useMemo(() => {
    if (
      !accountDetailsResults?.accountHoldings ||
      accountDetailsResults.accountHoldings.length === 0
    ) {
      return []
    }

    const groupedData: { [key: string]: any } = {}
    accountDetailsResults.accountHoldings?.forEach((item) => {
      const {
        fundName,
        fundCode,
        totalWithdrawal,
        totalSwitchIn,
        totalSwitchOut,
        totalTransferOut,
        totalTransferIn,
        totalContributionAmount,
        totalUnit,
        navDate,
      } = item

      const key = fundName || fundCode
      if (!key) return

      if (!groupedData[key]) {
        groupedData[key] = {
          fundName,
          fundCode,
          totalWithdrawal: 0,
          totalSwitchIn: 0,
          totalSwitchOut: 0,
          totalTransferOut: 0,
          totalTransferIn: 0,
          totalContributionAmount: 0,
          totalUnit: 0,
          navDate,
          navPerUnit: 0,
        }
      }

      groupedData[key].totalWithdrawal += Number(totalWithdrawal) || 0
      groupedData[key].totalSwitchIn += Number(totalSwitchIn) || 0
      groupedData[key].totalSwitchOut += Number(totalSwitchOut) || 0
      groupedData[key].totalTransferOut += Number(totalTransferOut) || 0
      groupedData[key].totalTransferIn += Number(totalTransferIn) || 0
      groupedData[key].totalUnit += Number(totalUnit) || 0
      // groupedData[key].navPerUnit += Number(navPerUnit) || 0
      groupedData[key].totalContributionAmount +=
        Number(totalContributionAmount) || 0
    })
    return Object.values(groupedData)
  }, [accountDetailsResults?.accountHoldings])

  const accountDetailsMappedData = useMemo(() => {
    const groupedData = transformedAccountDetailsPaginatedData || []
    const navDetails = accountDetailsResults?.navDetails

    return groupedData.map((dataItem) => {
      const navItem = navDetails?.find(
        (nav) =>
          nav.fundName === dataItem.fundName ||
          nav.fundCode === dataItem.fundCode
      )

      return {
        ...dataItem,
        fundName: navItem ? navItem.fundName : dataItem.fundName,
        fundCode: dataItem.fundCode,
        navDate: navItem?.navDate,
        navPerUnit: navItem ? navItem.navPerUnit : dataItem.navPerUnit || 0,
      }
    })
  }, [
    transformedAccountDetailsPaginatedData,
    accountDetailsResults?.navDetails,
  ])

  const rowsPerPage = 10
  const {
    currentPage,
    totalPages,
    handleNext,
    handlePrev,
    accountDetailsCurrentPage,
    accountDetailsTotalPages,
    handleAccountDetailsNext,
    handleAccountDetailsPrev,
    trxDetailsCurrentPage,
    trxDetailsTotalPages,
    handleTransactionHistoryNext,
    handleTransactionHistoryPrev,
    setCurrentPage,
    setAccountDetailsCurrentPage,
    setTrxDetailsCurrentPage,
    rwpDetailsCurrentPage,
    setRwpDetailsCurrentPage,
    rwpDetailsTotalPages,
    handleRWPDetailsNext,
    handleRWPDetailsPrev,
  } = usePagination({
    totalItems: contributionSummaryResults?.accountHoldings?.length,
    rowsPerPage,
    intialPage: 1,
    accountDetailsTotalItems:
      transformedAccountDetailsPaginatedData?.length + 1,
    trxDetailsTotalItems: transactionDetailsResults?.length,
    rwpDetailsTotalItems: rwpDetailsResults?.length,
  })

  const paginatedData = useMemo(() => {
    if (!Array.isArray(contributionSummaryResults?.accountHoldings)) return []

    const indexOfLastRow = currentPage * rowsPerPage
    const indexOfFirstRow = indexOfLastRow - rowsPerPage
    const currentRows =
      contributionSummaryResults?.accountHoldings &&
      contributionSummaryResults?.accountHoldings?.slice(
        indexOfFirstRow,
        indexOfLastRow
      )
    const formattedRows = currentRows?.map((row) => ({
      ...row,
      totalContributionAmount: row.totalContributionAmount
        ? Number(Number(row.totalContributionAmount).toFixed(2))
        : 0,
    }))

    return formattedRows
  }, [contributionSummaryResults?.accountHoldings, currentPage, rowsPerPage])

  const accountDetailspaginatedData = useMemo(() => {
    if (!Array.isArray(accountDetailsMappedData)) return []

    const indexOfLastRow = accountDetailsCurrentPage * rowsPerPage
    const indexOfFirstRow = indexOfLastRow - rowsPerPage
    const currentRows = accountDetailsMappedData?.slice(
      indexOfFirstRow,
      indexOfLastRow
    )
    return currentRows
  }, [accountDetailsMappedData, accountDetailsCurrentPage, rowsPerPage])

  const trxDetailsPaginatedData = useMemo(() => {
    if (!Array.isArray(transactionDetailsResults)) return []

    const indexOfLastRow = trxDetailsCurrentPage * rowsPerPage
    const indexOfFirstRow = indexOfLastRow - rowsPerPage
    const currentRows =
      transactionDetailsResults &&
      transactionDetailsResults?.slice(indexOfFirstRow, indexOfLastRow)

    return currentRows
  }, [transactionDetailsResults, trxDetailsCurrentPage, rowsPerPage])

  const rwpPaginatedData = useMemo(() => {
    if (!Array.isArray(rwpDetailsResults)) return []

    const indexOfLastRow = rwpDetailsCurrentPage * rowsPerPage
    const indexOfFirstRow = indexOfLastRow - rowsPerPage
    const currentRows = rwpDetailsResults?.slice(
      indexOfFirstRow,
      indexOfLastRow
    )
    return currentRows
  }, [rwpDetailsResults, rwpDetailsCurrentPage, rowsPerPage])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
    setCurrentPage(1)
    setAccountDetailsCurrentPage(1)
    setTrxDetailsCurrentPage(1)
    setRwpDetailsCurrentPage(1)
  }

  const handleAccountTabChange = (tab: string) => {
    setAccountActiveTab(tab)
    setCurrentPage(1)
    setAccountDetailsCurrentPage(1)
    setTrxDetailsCurrentPage(1)
    setRwpDetailsCurrentPage(1)
  }

  const { addNetworkErrorNotice } = useNotifications()
  useEffect(() => {
    if (status === 'error') {
      const errMsg = getErrorResponseCode(error)
      console.error(errMsg)
      addNetworkErrorNotice()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, error])

  const statements = useMemo(
    () => getCategorizedStatements(data?.statements ?? [], selectedCategory),
    [data, selectedCategory]
  )

  useEffect(() => {
    setSelectedRowIndex(null)
  }, [activeTab])

  const _statements = convertStatement(statements || [], itemsNum)
  return (
    <>
      <div className='mb-4'>
        <div className='w-full mb-6 h-10 flex'>
          {visibleCategories.map((category) => (
            <Button
              key={category}
              onClick={() => {
                setSelectedCategory(category)
                setActiveTab(StatementAccountTypeCategory.STATEMENT)
              }}
              className={`flex-1 flex justify-center items-center rounded-full border-solid border-2 mr-2 last:mr-0 ${
                selectedCategory === category
                  ? 'border-pi-principal-blue bg-pi-sky-blue-1'
                  : 'border-pi-gray-2'
              }`}
            >
              <span
                className={`font-bold ${
                  selectedCategory === category
                    ? 'text-pi-principal-blue'
                    : 'text-pi-gray-1'
                }`}
              >
                {t(category || '', { ns: 'statementTypeCategories' })}
              </span>
            </Button>
          ))}
        </div>
        {status === 'loading' ? (
          <div className='w-full h-[10vh] m-0 flex justify-center items-center'>
            <CircularProgress className='text-pi-principal-blue' />
          </div>
        ) : (
          <>
            <div className='p-4 bg-pi-gray-100 shadow-md rounded-md bg-[#f5f5f5]'>
              <TabSwitcher
                tabs={[
                  StatementAccountTypeCategory.STATEMENT,
                  StatementAccountTypeCategory.ACCOUNT,
                ]}
                activeTab={activeTab}
                onTabChange={handleTabChange}
                selectedCategory={selectedCategory}
              />

              {activeTab === StatementAccountTypeCategory.STATEMENT &&
                (_statements.length > 0 ? (
                  <StatementReport statements={_statements} />
                ) : (
                  <div className='pt-4'>
                    <span className='h-[10vh]'>{t('No item found')}</span>
                  </div>
                ))}
              {activeTab === StatementAccountTypeCategory.ACCOUNT && (
                <>
                  <DataTable
                    headers={Object.keys(contributionSummaryColumnMapping)}
                    columnMapping={contributionSummaryColumnMapping}
                    rows={transformedPaginatedData() || []}
                    selectedRowIndex={selectedRowIndex}
                    handleAccountChecked={(isChecked, index) => {
                      handleCheckboxChange(isChecked, index)
                    }}
                    isCheckbox={true}
                    status={contributionSummaryStatus}
                  />

                  {/* Pagination */}
                  {transformedPaginatedData().length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onNext={handleNext}
                      onPrev={handlePrev}
                    />
                  )}
                </>
              )}
            </div>

            <div>
              {selectedRowIndex !== null &&
                isAccountSectionVisiable &&
                activeTab === StatementAccountTypeCategory.ACCOUNT && (
                  <div className='mt-8'>
                    <div className='flex gap-6'>
                      <TabSwitcher
                        tabs={[
                          AccountTrxTypeCategory.ACCOUNT_DETAILS,
                          AccountTrxTypeCategory.TRANSACTION_HISTORY,
                          AccountTrxTypeCategory.RSP,
                          AccountTrxTypeCategory.RWP,
                          AccountTrxTypeCategory.DISTRIBUTOR_DETAILS,
                        ]}
                        activeTab={accountActiveTab}
                        onTabChange={handleAccountTabChange}
                      />
                    </div>

                    <ContributionDetails
                      titleHeader={accountActiveTab}
                      accountActiveTab={accountActiveTab}
                      ytdContributionDetails={
                        accountDetailsResults?.AccountYtdContributonList
                      }
                    />

                    {accountActiveTab === AccountTrxTypeCategory.RSP && (
                      <RSPDetails
                        rspDetails={rspDetailsResults}
                        status={rspDetailsStatus}
                      />
                    )}

                    {accountActiveTab ===
                      AccountTrxTypeCategory.DISTRIBUTOR_DETAILS && (
                      <DistributorDetails
                        distributorDetails={distributorDetailsResults?.[0]}
                        status={distributorDetailsStatus}
                      />
                    )}

                    {accountActiveTab ===
                      AccountTrxTypeCategory.ACCOUNT_DETAILS && (
                      <>
                        <DataTable
                          headers={Object.keys(accountDetailsColumnMapping)}
                          columnMapping={accountDetailsColumnMapping}
                          rows={accountDetailspaginatedData || []}
                          selectedRowIndex={selectedRowIndex}
                          handleAccountChecked={handleCheckboxChange}
                          isCheckbox={false}
                          status={accountDetailsStatus}
                        />
                        {/* Pagination */}
                        {accountDetailspaginatedData.length > 0 && (
                          <Pagination
                            currentPage={accountDetailsCurrentPage}
                            totalPages={accountDetailsTotalPages}
                            onNext={handleAccountDetailsNext}
                            onPrev={handleAccountDetailsPrev}
                          />
                        )}
                      </>
                    )}
                    {accountActiveTab ===
                      AccountTrxTypeCategory.TRANSACTION_HISTORY && (
                      <>
                        <DataTable
                          headers={Object.keys(trxDetailsColumnMapping)}
                          columnMapping={trxDetailsColumnMapping}
                          rows={trxDetailsPaginatedData || []}
                          selectedRowIndex={selectedRowIndex}
                          handleAccountChecked={handleCheckboxChange}
                          isCheckbox={false}
                          status={transactionDetailsStatus}
                        />
                        {/* Pagination */}
                        {trxDetailsPaginatedData.length > 0 && (
                          <Pagination
                            currentPage={trxDetailsCurrentPage}
                            totalPages={trxDetailsTotalPages}
                            onNext={handleTransactionHistoryNext}
                            onPrev={handleTransactionHistoryPrev}
                          />
                        )}
                      </>
                    )}
                    {accountActiveTab === AccountTrxTypeCategory.RWP && (
                      <>
                        <DataTable
                          headers={Object.keys(rwpDetailsColumnMapping)}
                          columnMapping={rwpDetailsColumnMapping}
                          rows={rwpPaginatedData || []}
                          selectedRowIndex={selectedRowIndex}
                          handleAccountChecked={handleCheckboxChange}
                          isCheckbox={false}
                          status={rwpDetailsStatus}
                        />
                        {/* Pagination */}
                        {rwpPaginatedData.length > 0 && (
                          <Pagination
                            currentPage={rwpDetailsCurrentPage}
                            totalPages={rwpDetailsTotalPages}
                            onNext={handleRWPDetailsNext}
                            onPrev={handleRWPDetailsPrev}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
            </div>
          </>
        )}
      </div>
      {activeTab === StatementAccountTypeCategory.STATEMENT && (
        <ButtonWithLoading
          variant='outlined'
          size='large'
          className='w-full'
          onClick={() => {
            dispatch(setIsUserIdle())
            setItemsNum(itemsNum + NUM_PER_BETCH)
          }}
          disabled={itemsNum > (statements || []).length}
        >
          {t('Load more statement')}
        </ButtonWithLoading>
      )}
    </>
  )
}

const convertStatement = (statements: StatementItem[], itemsNum: number) => {
  let _statements: convertedStatementItemProps[] = []

  statements.slice(0, itemsNum).forEach((sItem) => {
    const date = dayjs.utc(sItem.statementDate).local()
    const month = date.format('MMMM')
    const dateStr = date.format('DD MMM YYYY')

    const _currMonth = _statements.find((mItem) => mItem.month === month)
    if (!_currMonth) {
      //append new month to _statements
      _statements.push({
        month,
        items: [
          {
            dateStr,
            items: [sItem],
          },
        ],
      })
    } else {
      const _currDate = _currMonth.items.find(
        (dItem) => dItem.dateStr === dateStr
      )
      if (!_currDate) {
        //append new date to current month
        _currMonth.items.push({
          dateStr,
          items: [sItem],
        })
      } else {
        //append new item to current date
        _currDate.items.push(sItem)
      }
    }
  })

  return _statements
}

export default Statements
