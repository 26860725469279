interface PaginationProps {
  currentPage: number
  totalPages: number
  onNext: () => void
  onPrev: () => void
}

const Pagination = ({
  currentPage,
  totalPages,
  onNext,
  onPrev,
}: PaginationProps) => {
  return (
    <div className='flex justify-end items-center mt-4'>
      <button
        onClick={onPrev}
        disabled={currentPage === 1}
        className='px-4 py-2 border bg-[#c6c6c6] text-pi-gray-6 rounded disabled:opacity-50'
      >
        &lt;
      </button>
      <span className='px-4 py-2 border text-black'>
        {currentPage} of {totalPages}
      </span>
      <button
        onClick={onNext}
        disabled={currentPage === totalPages}
        className='px-4 py-2 border bg-[#c6c6c6] text-pi-gray-6 rounded disabled:opacity-50'
      >
        &gt;
      </button>
    </div>
  )
}

export default Pagination
