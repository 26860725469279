import { useQuery } from '@tanstack/react-query'
import { StatementApi as StatementApiClass } from '@pimy-b2cweb/apiclient-customer-stmt'
import { BaseApiConfig, apiClient } from './api-config'

import { store } from '@/stores'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { StatusEnum } from '@/config'

export const StmtApi = new StatementApiClass(BaseApiConfig, apiClient)
const source = 'B2C'

export const useQueryStmtRecent = () => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-recent', token, status],
    queryFn: async () => {
      const {
        data: {
          body: { statements, accountNumbers, accountsList },
        },
      } = await StmtApi.recent({
        stmtRecentReqBodyPayload: {
          accountNumbers: [],
        },
      })
      return { statements, accountNumbers, accountsList }
    },
    enabled: !!token && status === StatusEnum.LoggedIn,
  })
}

export const useQueryStmtAccountDetails = ({ accountNo, idNo }: { accountNo: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-account-details', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryAccountDetails({ source, accountNo, idNo })
      const accountHoldings = response.data.body?.accountHoldings
      const AccountYtdContributonList = response.data.body?.AccountYtdContributonList
      const navDetails = response.data.body?.navDetails
      return { accountHoldings, AccountYtdContributonList, navDetails }
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}

export const useQueryStmtTransactionDetails = ({ accountNo, idType, idNo }: { accountNo: string, idType: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-transaction-details', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryTransactionDetails({ source, accountNo, idType, idNo })
      return response.data.body?.transactionLedger
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}

export const useQueryStmtContributionSummary = ({ accountNo, idNo }: { accountNo: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-contribution-summary', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryContributionSummary({ source, accountNo, idNo })
      const investmentApproach = response.data.body?.investmentApproach
      const accountHoldings = response.data.body?.accountHoldings
      return { accountHoldings, investmentApproach }
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}

export const useQueryStmtRspDetails = ({ accountNo, idNo }: { accountNo: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-rsp-details', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryRspDetails({ source, accountNo, idNo })
      return response.data.body?.rspDetails
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}

export const useQueryStmtRwpDetails = ({ accountNo, idNo }: { accountNo: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-rwp-details', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryRwpDetails({ source, accountNo, idNo })
      return response.data.body?.rwpDetails
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}

export const useQueryStmtDistributorDetails = ({ accountNo, idNo }: { accountNo: string, idNo: string }, { enabled }: { enabled: boolean }) => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-distributor-details', accountNo],
    queryFn: async () => {
      const response = await StmtApi.queryDistributorDetails({ source, accountNo, idNo })
      return response.data.body?.distributorDetails
    },
    enabled: !!token && status === StatusEnum.LoggedIn && enabled,
  })
}
