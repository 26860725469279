import { Card } from '@mui/material'
import MainLogo from '../MainLogo'
import { PropsWithChildren } from 'react'

const DelistingClosedLayout = ({
  className = '',
  children,
}: PropsWithChildren<{
  className?: string
}>) => {
  return (
    <div className='response-container'>
      <Card className={className}>
        <MainLogo />
        {children}
      </Card>
    </div>
  )
}

export default DelistingClosedLayout
