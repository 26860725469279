import { CircularProgress } from '@mui/material'
import { StmtRspDetailsResBodyPayloadRspDetails } from '@pimy-b2cweb/apiclient-customer-stmt'

interface ContributionDetailsProps {
  rspDetails: StmtRspDetailsResBodyPayloadRspDetails | undefined
  status: string
}

const RSPDetails = ({ rspDetails, status }: ContributionDetailsProps) => {
  const getDateFormat = (date?: string): string => {
    if (!date) return ''
    const dateObj = new Date(date)
    return dateObj.toISOString().split('T')[0]
  }

  const getFrequency = (frequency: string | undefined) => {
    let item
    if (frequency === 'M' || frequency === 'm') {
      item = 'Monthly'
    } else if (frequency === 'Q' || frequency === 'q') {
      item = 'Quarterly'
    } else if (frequency === 'H' || frequency === 'h') {
      item = 'Half yearly'
    } else if (frequency === 'Y' || frequency === 'y') {
      item = 'Yearly'
    } else {
      item = frequency
    }
    return item
  }

  return (
    <>
      {status === 'loading' ? (
        <div className='w-full h-[10vh] m-0 flex justify-center items-center'>
          <CircularProgress className='text-pi-principal-blue' />
        </div>
      ) : (
        <div className='mt-4 flex flex-row'>
          <div className='w-[40%]'>
            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Bank Name
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.bankName ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {rspDetails?.bankName}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Bank Account No
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.bankAccountNo ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {rspDetails?.bankAccountNo}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Regular Contribution Amount
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.standingAmount ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {rspDetails?.standingAmount}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Status
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.rspStatus ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {rspDetails?.rspStatus}
              </span>
            </div>
          </div>
          <div className='w-[40%]'>
            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Deduction Date
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.paymentDate ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {getDateFormat(rspDetails?.paymentDate)}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Last Deduction Date
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.lastPaymentDate ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {getDateFormat(rspDetails?.lastPaymentDate)}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Next Deduction Date
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.nextBillingDate ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {getDateFormat(rspDetails?.nextBillingDate)}
              </span>
            </div>

            <div className='flex gap-8 mb-2'>
              <label
                className='text-right block text-sm font-medium text-pi-gray-2 w-[45%]
       mb-1'
              >
                Frequency
              </label>
              <span
                className={`border-2 border-black w-[70%] h-[10%] bg-pi-gray-4 ${
                  rspDetails?.frequency ? 'p-[2px]' : 'p-[14px]'
                }`}
              >
                {getFrequency(rspDetails?.frequency)}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RSPDetails
